import { SpartaAPI } from "classes/SpartaAPI";
import { getTenorNameByCode } from "utils/helpers";

export type KeyValue<Key = string, Value = string> = [Key, Value];

type PRODUCT_KEYS = "CODE" | "NAME";
type PERIOD_KEYS = "TENOR" | "DATE";

const KEYVALUE_SEPARATOR = ":";
const PAIR_SEPARATOR = "|";

export const serializeParam = <Key = string>(keyValues: KeyValue<Key>[]): string =>
  keyValues.map(([key, value]) => `${key}${KEYVALUE_SEPARATOR}${value}`).join(PAIR_SEPARATOR);

export const deserializeParam = <Key = string>(param: string): KeyValue<Key>[] =>
  param.split(PAIR_SEPARATOR).map((keyValue) => {
    const [key, value] = keyValue.split(KEYVALUE_SEPARATOR);

    return [key, value] as KeyValue<Key>;
  });

export const serializeProduct = (keyValues: KeyValue<PRODUCT_KEYS>[]): string => serializeParam(keyValues);

export const getParam = <Key>(args: string | KeyValue<Key>[], param: Key): string | undefined => {
  const params = typeof args === "string" ? deserializeParam<Key>(args) : args;
  return params.find(([k]) => k === param)?.[1];
};

const computeProductValue = (key: PRODUCT_KEYS, value: string): string | null => {
  switch (key) {
    case "CODE":
      return value;
    case "NAME": {
      return SpartaAPI.getProductCodeByLabel(value);
    }
    default:
      return null;
  }
};

export const deserializeProduct = (product: string): KeyValue<PRODUCT_KEYS, string | null>[] => {
  const keyValues = deserializeParam<PRODUCT_KEYS>(product);

  return keyValues.map(([key, value]) => [key, computeProductValue(key, value)]);
};

export const serializePeriod = (keyValues: KeyValue<PERIOD_KEYS>[]): string => serializeParam(keyValues);

const computePeriodValue = (key: PERIOD_KEYS, value: string): string | null => {
  switch (key) {
    case "TENOR":
      return getTenorNameByCode(value);
    case "DATE":
      return value;
    default:
      return null;
  }
};

export const deserializePeriod = (period: string): KeyValue<PERIOD_KEYS, string | null>[] => {
  const keyValues = deserializeParam<PERIOD_KEYS>(period);

  return keyValues.map(([key, value]) => [key, computePeriodValue(key, value)]);
};
