import { UserMe } from "services/auth/types";
import { Frequency } from "types";
import { isEntitlementEnabled } from "utils/license";

export const updateRibbonByUser = (
  user: UserMe | undefined,
  frequency: Frequency,
  includePause = true
): Promise<void> => {
  const hasUser = !!user;
  const hasLiveCurves = hasUser && isEntitlementEnabled(user, "liveCurves");

  const controls: Office.Control[] = [
    {
      id: "PortfoliosButton",
      enabled: hasUser && hasLiveCurves,
    },
    {
      id: "ProductsButton",
      enabled: hasUser && hasLiveCurves,
    },
    {
      id: "GuideButton",
      enabled: hasUser && hasLiveCurves,
    },
    {
      id: "LiveButton",
      enabled: hasUser && frequency !== "live",
    },
    {
      id: "RecommendedButton",
      enabled: hasUser && frequency !== "recommended",
    },
    {
      id: "IdleButton",
      enabled: hasUser && frequency !== "idle",
    },
  ];

  if (includePause) {
    controls.push({
      id: "PauseButton",
      enabled: hasUser && frequency !== "pause",
    });
  }

  return Office.ribbon
    .requestUpdate({
      tabs: [
        {
          id: "TabSparta",
          controls,
        },
      ],
    })
    .catch(console.error);
};
