import { getParam } from "./utils";

export const debugGeneral = (args: string): Excel.CellValue | string | null => {
  const debugType = getParam(args, "debug");
  switch (debugType) {
    case "basic":
      return "Hello from Debug!";
    case "products":
      return {
        type: "String",
        basicValue: global.Sparta.dumpProducts(),
      };
    case "user":
      return {
        type: "String",
        basicValue: global.Sparta.dumpUser(),
      };
    case "queue":
      return {
        type: "String",
        basicValue: global.Sparta.dumpQueue(),
      };
    case "subs":
      return {
        type: "String",
        basicValue: global.Sparta.dumpSubscriptions(),
      };
    case "connection":
      return {
        type: "String",
        basicValue: global.Sparta.isConnected().toString(),
      };
  }
  return null;
};
