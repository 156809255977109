export const enableUpdateButton = () =>
  Office.ribbon.requestUpdate({
    tabs: [
      {
        id: "TabSparta",
        controls: [
          {
            id: "PauseButton",
            enabled: true,
          },
        ],
      },
    ],
  });
