import { AxiosError } from "axios";
import { refresh, trackAuthBlocked, trackAuthMultipleSession } from "services";
import { SessionRefreshError } from "services/auth/types";

import { clearLocalStorage } from "./localStorage";

const TOKEN_KEY = "accessToken";
const REFRESH_KEY = "refreshToken";
const TOKEN_ERROR = "tokenError";

type TokenError = {
  title: string;
  description: string;
};

export const setToken = (token: string) => localStorage.setItem(TOKEN_KEY, token);
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getRefreshToken = () => localStorage.getItem(REFRESH_KEY);

export const removeTokenError = () => localStorage.removeItem(TOKEN_ERROR);
export const setTokenError = (tokenError: TokenError) => localStorage.setItem(TOKEN_ERROR, JSON.stringify(tokenError));
export const getTokenError = () => {
  try {
    const value = localStorage.getItem(TOKEN_ERROR);

    if (value) return JSON.parse(value) as TokenError;
  } catch {
    return null;
  }

  return null;
};

const REFRESH_ERRORS_MAP: Record<string, string> = {
  user_blocked_web: "User Blocked",
  multissession_gate_failed: "Disconnected",
  excel_disabled: "Excel Disabled",
};

const catchRefresh = (error: AxiosError<SessionRefreshError>) => {
  const { code, message } = error.response?.data || {};

  if (code && !!REFRESH_ERRORS_MAP[code]) {
    if (code === "multissession_gate_failed") trackAuthMultipleSession();
    if (code === "user_blocked_web") trackAuthBlocked();

    setTokenError({
      description: message || "",
      title: REFRESH_ERRORS_MAP[code] || "",
    });
    global.Sparta.openTaskPane();
  }
};

export const refreshToken = async () => {
  try {
    if (getToken()) {
      const response = await refresh();

      if (response.result === "allow") {
        setToken(response.accessToken);

        return true;
      } else {
        clearLocalStorage();
      }
    }

    return false;
  } catch (error) {
    if (!navigator.onLine) {
      return false;
    }

    clearLocalStorage();

    if (error instanceof AxiosError) {
      catchRefresh(error);
    }

    return false;
  }
};
