import { v4 as uuidv4 } from "uuid";

import { Subscription } from "./Subscription";

export class Subject<ValueType> {
  private value: ValueType;
  private subscriptions = new Map<string, Subscription<ValueType>>();

  public observed = false;

  constructor(value: ValueType) {
    this.value = value;
  }

  public subscribe(callback: Subscription<ValueType>["callback"], updateAtStart = true): Subscription<ValueType> {
    const id = uuidv4();

    const subscription = new Subscription(id, callback, this);

    this.subscriptions.set(id, subscription);
    this.observed = true;

    if (updateAtStart) {
      callback(this.value);
    }

    return subscription;
  }

  public unsubscribe(id: string): void {
    this.subscriptions.delete(id);
    this.observed = this.isObserved();
  }

  public getValue(): ValueType {
    return this.value;
  }

  public next(value: ValueType): void {
    this.value = value;
    this.notifySubscribers();
  }

  private isObserved(): boolean {
    return Array.from(this.subscriptions.values()).some((subscription) => subscription.isOpened());
  }

  public notifySubscribers(): void {
    const value = this.value;

    Array.from(this.subscriptions.values()).forEach((subscription) => subscription.notify(value));
  }
}
