import { identify, track } from "segment";

/**
 * When we get the user data, and it's identified on Segment.
 * @param {number} id - The user id.
 * @param {string} username - The username.
 * @param {string} email - The user email.
 * @param {Record<string, any>} traits - An object to set up traits on Segment. More on: https://segment.com/docs/connections/spec/identify/
 */
export const trackIdentifyUser = (
  id: number,
  username: string,
  email: string,
  traits: Record<string, any> = {}
): void => {
  identify(id.toString(), { username, email, environment: process.env.SPARTA_ENV, origin: "excel", ...traits });
};

/**
 * When the user receive an error after making the login call.
 * @param {string} email
 */
export const trackLoginError = (email: string): void => {
  track("Login error", { email });
};

/**
 * When logout is activated due to the user to be blocked.
 */
export const trackAuthBlocked = (): void => {
  track("Auth blocked");
};

/**
 * When a user is logged out due to start another session with the same authentication.
 */
export const trackAuthMultipleSession = (): void => {
  track("Auth Multiple Session", {}, false);
};

/**
 * When the user clicks on the Login button in the ribbon menu.
 */
export const trackLoginButtonClicked = (): void => {
  track("Login Button Clicked");
};

/**
 * Logout Clicked.
 * @param {string} screen - E.g. 'Add Portfolio' or ‘Add Product’
 */
export const trackLogoutClicked = (screen: string): void => {
  track("Logout Clicked", { screen });
};
