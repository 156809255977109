import { Subject } from "./Subject";

export class Subscription<ValueType> {
  private id: string;
  private subject: Subject<ValueType>;
  private callback: (value: ValueType) => void;
  private closed: boolean;

  constructor(id: string, callback: (value: ValueType) => void, subject: Subject<ValueType>) {
    this.id = id;
    this.callback = callback;
    this.subject = subject;
    this.closed = false;
  }

  public isOpened(): boolean {
    return !this.closed;
  }

  public notify(value: ValueType): void {
    if (!this.closed) {
      this.callback(value);
    }
  }

  public unsubscribe(): void {
    this.closed = true;
    this.subject.unsubscribe(this.id);
  }
}
