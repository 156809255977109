import { differenceInHours, isValid } from "date-fns";
import { UserMe } from "services/auth/types";

const USER_KEY = "SPARTA_USER";
const REQUESTED_TIME_KEY = "LAST_USER_REQUEST";

export const getCachedUser = () => {
  const cachedUserStringified = localStorage.getItem(USER_KEY);

  try {
    const cachedUser = JSON.parse(cachedUserStringified || "") as UserMe;

    const lastUserRequest = new Date(localStorage.getItem(REQUESTED_TIME_KEY) || "");

    if (isValid(lastUserRequest) && differenceInHours(new Date(), lastUserRequest) < 24) {
      return cachedUser;
    }
  } catch {
    return undefined;
  }
};

export const removeCachedUser = () => {
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(REQUESTED_TIME_KEY);
};

export const setCachedUser = (user: UserMe) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
  localStorage.setItem(REQUESTED_TIME_KEY, new Date().toISOString());
};
