import { datadogRum } from "@datadog/browser-rum";
import { SpartaAPI } from "classes/SpartaAPI";
import type { Metadata, ObservableMetadataProperties } from "classes/types";
import { addMonths, differenceInMilliseconds, startOfMonth } from "date-fns";
import { getNumberFormatByDecimalPlaces, utcNow } from "utils/helpers";

import { debugGeneral } from "./debug";
import { deserializePeriod, deserializeProduct } from "./utils";

/**
 * Displays the requested product Property
 * @customfunction
 * @param args Custom arguments
 * @param product Searialized list of product CODE and NAME.
 * @param property Product property to show
 *
 * @helpurl https://knowledge.spartacommodities.com/how-to-use-the-excel-plugin#formulas
 */
export function getProductMetadata(args: string, product: string, property: string): any {
  try {
    const debugReturn = debugGeneral(args);
    if (debugReturn !== null) {
      return debugReturn;
    }

    const [[, productCode]] = deserializeProduct(product);

    if (!productCode) {
      datadogRum.addError("[getProductMetadata] Product not found", {
        args: {
          product,
          property,
        },
        productCode,
      });
      return {
        type: "String",
        basicValue: "",
      };
    }

    const metadata = SpartaAPI.getProductMetadata(productCode);

    if (!metadata || Object.hasOwnProperty.call(metadata, property) === false) {
      datadogRum.addError("[getProductMetadata] Metadata not found", {
        args: {
          product,
          property,
        },
        productCode,
        property,
      });
      return {
        type: "String",
        basicValue: "",
      };
    }

    const observableProperties: ObservableMetadataProperties[] = ["name", "units", "type", "priceType"];

    const isObservable = observableProperties.includes(property as ObservableMetadataProperties);

    if (!isObservable) {
      datadogRum.addError(`[getProductMetadata] Property is not observable`, {
        args: {
          product,
          property,
        },
        productCode,
        property,
      });

      return {
        type: "String",
        basicValue: "",
      };
    }

    const ret: Excel.StringCellValue = {
      type: "String",
      basicValue:
        metadata && property in metadata && !!metadata[property as ObservableMetadataProperties]
          ? metadata[property as ObservableMetadataProperties].toString()
          : "",
    };

    return ret;
  } catch (error) {
    datadogRum.addError("[getProductMetadata] Unexpected error", { error });
  }
}

/**
 * Displays the last value for a symbol
 * @customfunction
 * @streaming
 * @param args Custom arguments
 * @param product Searialized list of product CODE and NAME.
 * @param period Searialized list of TENOR and DATE.
 *
 * @helpurl https://knowledge.spartacommodities.com/how-to-use-the-excel-plugin#formulas
 */
export function observeLiveCurve(
  args: string,
  product: string,
  period: string,
  invocation: CustomFunctions.StreamingInvocation<any[][]>
) {
  try {
    const debugReturn = debugGeneral(args);
    if (debugReturn !== null) {
      return invocation.setResult([[debugReturn]]);
    }

    const updateResult = () => {
      const [[, productCode]] = deserializeProduct(product);
      const [[, tenorName]] = deserializePeriod(period);

      if (!productCode || !tenorName) {
        datadogRum.addError("[observeLiveCurve] Product or Tenor not found", {
          args: {
            product,
            period,
          },
          productCode,
          tenorName,
        });

        return invocation.setResult([[{ basicValue: "", type: "String" } as Excel.StringCellValue]]);
      }

      return global.Sparta.busForProduct(productCode, tenorName).subscribe((price) => {
        const metadata = SpartaAPI.getProductMetadata(productCode);

        if (price === null || !metadata) {
          if (!metadata) {
            datadogRum.addError("[observeLiveCurve] Metadata not found", {
              args: {
                product,
                period,
              },
              productCode,
              tenorName,
            });
          }

          return invocation.setResult([[{ basicValue: "", type: "String" } as Excel.StringCellValue]]);
        }

        if (metadata.priceType === "Datetime") {
          return invocation.setResult([[{ basicValue: price, type: "String" } as Excel.StringCellValue]]);
        } else {
          return invocation.setResult([
            [
              {
                basicValue: price,
                numberFormat: getNumberFormatByDecimalPlaces(metadata?.decimalPlaces),
                type: "FormattedNumber",
              } as Excel.FormattedNumberCellValue,
            ],
          ]);
        }
      });
    };

    let subscription = updateResult();

    let timeoutId: NodeJS.Timeout | undefined = undefined;
    const keyValues = deserializePeriod(period);
    const someValidTenor = keyValues.some(([key, value]) => key === "TENOR" && value !== null);

    if (someValidTenor) {
      const now = utcNow();
      const nextMonth = startOfMonth(addMonths(now, 1));

      timeoutId = setTimeout(() => {
        subscription?.unsubscribe();
        subscription = updateResult();
      }, differenceInMilliseconds(nextMonth, now));
    }

    invocation.onCanceled = () => {
      clearTimeout(timeoutId);
      subscription?.unsubscribe();
    };
  } catch (error) {
    datadogRum.addError("[observeLiveCurve] Unexpected error", { error });
  }
}

/**
 * Displays month and day.
 * @customfunction
 * @streaming
 * @param args Custom arguments
 * @param period Searialized list of TENOR and DATE.
 *
 * @helpurl https://knowledge.spartacommodities.com/how-to-use-the-excel-plugin#formulas
 */
export function getPeriod(args: string, period: string, invocation: CustomFunctions.StreamingInvocation<any>) {
  try {
    const debugReturn = debugGeneral(args);
    if (debugReturn !== null) {
      return invocation.setResult(debugReturn);
    }

    const updateResult = () => {
      const [[, tenorName]] = deserializePeriod(period);

      invocation.setResult({
        basicValue: tenorName ?? "",
        type: "String",
      } as Excel.StringCellValue);
    };

    let timeoutId: NodeJS.Timeout | undefined = undefined;
    const keyValues = deserializePeriod(period);
    const someValidTenor = keyValues.some(([key, value]) => key === "TENOR" && value !== null);

    if (someValidTenor) {
      const now = utcNow();
      const nextMonth = startOfMonth(addMonths(now, 1));

      timeoutId = setTimeout(updateResult, differenceInMilliseconds(nextMonth, now));
    }

    updateResult();
    invocation.onCanceled = () => clearTimeout(timeoutId);
  } catch (error) {
    datadogRum.addError("[getPeriod] Unexpected error", { error });
  }
}

CustomFunctions.associate("GETPRODUCTMETADATA", getProductMetadata);
CustomFunctions.associate("OBSERVELIVECURVE", observeLiveCurve);
CustomFunctions.associate("GETPERIOD", getPeriod);