export const PERIOD_CODES = [
  "BOM",
  "M01",
  "M02",
  "M03",
  "M04",
  "M05",
  "M06",
  "M07",
  "M08",
  "M09",
  "M10",
  "M11",
  "M12",
  "M13",
  "M14",
  "M15",
  "M16",
  "BOQ",
  "Q01",
  "Q02",
  "Q03",
  "Q04",
  "BOY",
  "CL1",
  "CL2",
];

const TOTAL_ROWS = PERIOD_CODES.length;

export const TOTAL_ROWS_WITH_PRODUCT_HEADER = TOTAL_ROWS + 2;
